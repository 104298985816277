import React from 'react';
import Reveal from 'react-reveal/';
const HRBanner = (props) =>{
    let BannerData = props.BannerData;
    return(
        <section className="startup_banner_area_three">
                <div className="container custom_container">
                    <div className="row">
                        <div className="col-lg-5 d-flex align-items-center">
                            <div className="agency_content">
                            <Reveal effect="fadeInUp">
                                {
                                    BannerData.AgencyText.map(Agency =>{
                                        return(
                                            <React.Fragment key={Agency.id}>
                                                <h2 className="f_700 t_color3 mb_40 wow fadeInLeft" data-wow-delay="0.3s">{Agency.btitle}</h2>
                                                <p className="f_400 l_height28 wow fadeInLeft" data-wow-delay="0.4s">{Agency.description}</p>
                                            </React.Fragment>
                                        )
                                    })
                                }
                                <div className="action_btn d-flex align-items-center mt_60">
                                    <a href="/getstarted" className="btn_hover agency_banner_btn wow fadeInLeft" data-wow-delay="0.5s">Get Started</a>
                                    <a href="/product" className="agency_banner_btn_two wow fadeInLeft" data-wow-delay="0.7s">Learn More</a>
                                </div>
                                </Reveal>
                            </div>
                        </div>
                        <div className="col-lg-7 text-right">
                            <Reveal effect="fadeInRight"><img className="protype_img wow fadeInRight" data-wow-delay="0.3s" src={require('../../img/home4/businessreview-01.png')} alt=""/></Reveal>
                        </div>
                    </div>
                    </div>
        </section>
    )
}
export default HRBanner;