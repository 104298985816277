import React, {Component} from 'react';
import { BrowserRouter as Router, Switch} from 'react-router-dom';
/*------ Pages-----*/
import About from './Pages/About';
import Product from './Pages/Product';
import Contact from './Pages/Contact';
import GetStarted from './Pages/GetStarted';
import ScrollToTopRoute from './ScrollToTopRoute';
import HomePage from './Pages/Homepage';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsOfService from './Pages/TermsOfService';

class App extends Component{
    componentDidMount() {
      this.props.hideLoader();
  }
  render(){
    return(
        <Router>
           <Switch>
             <ScrollToTopRoute exact={true} path={'/'} component={HomePage} />
             <ScrollToTopRoute path="/about" component={About} />
             <ScrollToTopRoute path="/product" component={Product} />
             <ScrollToTopRoute path="/contact" component={Contact} />
             <ScrollToTopRoute path="/getstarted" component={GetStarted} />
             <ScrollToTopRoute path="/privacypolicy" component={PrivacyPolicy} />
             <ScrollToTopRoute path="/termsofservice" component={TermsOfService} />
           </Switch>
        </Router>
    );
  }
}

export default App;

