import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import PrototypeFooter from '../components/Footer/PrototypeFooter';
import FooterData from '../components/Footer/FooterData';
import AgencyAction from '../components/AgencyAction';
import StartupFeatures from '../components/Features/StartupFeatures';
import AppFeaturesTwo from '../components/Features/AppFeaturesTwo';
import Features from '../components/Features/Features';

import { Helmet } from "react-helmet"

const browserTitle = "Product - Survaye";

const Product = () => {
    return(
        <>
        <Helmet>
          <title>{browserTitle}</title>
        </Helmet>
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Our Product" Pdescription=""/>
            <AppFeaturesTwo/>
            <Features/>
            <StartupFeatures/>
            <AgencyAction/>
            <PrototypeFooter rclass={'payment_footer_area_two'} FooterData={FooterData}/>
        </div>
        </>
    )
}
export default Product;