import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import Sservice from "../components/Service/Sservice/Sservice";
import ServiceData from "../components/Service/ServiceData";
import PrototypeFooter from "../components/Footer/PrototypeFooter";
import FooterData from "../components/Footer/FooterData";
import AgencyAction from "../components/AgencyAction";

import { Helmet } from "react-helmet";

const browserTitle = "About - Survaye";

const About = () => {
  return (
    <>
      <Helmet>
        <title>{browserTitle}</title>
      </Helmet>
      <div className="body_wrapper">
        <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu" />
        <Breadcrumb
          breadcrumbClass="breadcrumb_area"
          imgName="breadcrumb/banner_bg.png"
          Ptitle="About Us"
          Pdescription=""
        />
        <Sservice ServiceData={ServiceData} />
        <AgencyAction />
        <PrototypeFooter
          rclass={"payment_footer_area_two"}
          FooterData={FooterData}
        />
      </div>
    </>
  );
};
export default About;
