import React, { Component, Fragment } from "react";
import Fade from "react-reveal/Fade";
import Slider from 'react-slick';

class Sservice extends Component {
  render() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    
    return (
      <Fragment>
        <section className="seo_features_one sec_pad">
          <div className="container">
            <div className="row flex-row-reverse">
              <div className="col-lg-6">
                <div className="seo_features_img">
                  <div className="round_circle"></div>
                  <div className="round_circle two"></div>
                  <img
                    src={require("../../../img/seo/features_img.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <Fade bottom cascade>
                  <div className="seo_features_content">
                    <h2 className="wow fadeInUp">
                      It's our job to make it easy for you.
                    </h2>
                    <h6 className="wow fadeInUp">
                      As business owners ourselves, we understand that there is only so much time in the day. So we make our product easy to learn, and even easier to use.
                    </h6>
                    <p className="wow fadeInUp">
                      You already have to deal with a hundred other technologies, with customers, with work-life balance, and so much more. This is what we understand, so everything we do with our platform we just want to make it easy for you.
                    </p>
                    <a
                      href="/product"
                      className="seo_btn seo_btn_one btn_hover wow fadeInUp"
                    >
                      Learn More
                    </a>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </section>
        <section className="agency_about_area d-flex bg_color mb-4">
                <div className="col-lg-6 about_content_left ">
                    <div className="about_content mb_30">
                        <h2 className="f_size_30 f_700 l_height45 mb_20">As a company, we believe that it's important to enjoy what you do and have fun!</h2>
                        <p className="f_size_15 f_400 mb_40">We get it, jobs can be stressful. But we try our best to make work enjoyable and make it fun. Whether you are working for us, or working with us, we want the relationship to be positive.</p>
                        <p className="f_size_15 f_400 mb_40"></p>
                        <a href="/contact" className="about_btn">Questions for us?</a>
                    </div>
                </div>
                <div className="col-lg-6 about_img">
                    <Slider className="about_img_slider" {...settings}>
                        <div className="item">
                            <div className="about_item w45">
                                <img src={require('../../../img/home4/coltsgame.jpg')} alt=""/>
                                <div className="about_text">
                                    <span className="br"></span>
                                    <h5 className="f_size_18 l_height28 mb-0">Indianapolis Colts game</h5>
                                </div>
                            </div>
                            <div className="about_item w55">
                                <img src={require('../../../img/home4/coworking-space1.jpg')} alt=""/>
                                <div className="about_text text_two">
                                    <span className="br"></span>
                                    <h5 className="f_size_18 l_height28 mb-0">Coworking space</h5>
                                </div>
                            </div>
                            <div className="about_item w55">
                                <img src={require('../../../img/home4/16bit.jpg')} alt=""/>
                                <div className="about_text text_two">
                                    <span className="br"></span>
                                    <h5 className="f_size_18 l_height28 mb-0">Trip to 16-bit</h5>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            <section className="payment_testimonial_area">
                <div className="container">
                    <div className="row payment_testimonial_info flex-row-reverse">
                        <div className="col-lg-7 d-flex align-items-center">
                            <div className="testimonial_content">
                                <div className="icon">,,</div>
                                <p className="f_p f_size_20">“When people, whether they're employees or customers, think about our business, I want it to be positive. I want them to look forward to the relationships we can build together.”</p>
                                <div className="author f_600 f_p t_color f_size_20">Nick Jefferson</div>
                                <div className="author_description f_p f_size_15">Founder</div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="testimonial_img">
                                <img src={require ('../../../img/home9/nick_jefferson.jpg')} alt="" className="shadow card p-3"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
      </Fragment>
    );
  }
}
export default Sservice;
