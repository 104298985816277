import React, { Component } from "react";

const PostMessage = () => {
  return (
    <div className="contact_form col-lg-9">
      <div className="text-center">
        <h1>Thanks for your message!</h1>
        <br />
        <p>We'll have someone take a look at it soon and get back to you.</p>
      </div>
    </div>
  );
};

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      messageSent: false
    };
  }

  changeValue = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  sendMessage = e => {
    e.preventDefault();
    const { name, email, subject, message } = this.state;
    fetch(`https://app.survaye.com/contactus/send`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name,
        email,
        subject,
        message
      })
    });
    this.setState({
      messageSent: true
    });
  };

  render() {
    return (
      <section className="contact_info_area sec_pad bg_color">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="contact_info_item">
                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">
                  Want to try us out?
                </h6>
                <p className="f_400 f_size_15">
                  If you want to try out our product, please use the "Get
                  Started" button or{" "}
                  <a href="/getstarted">follow this link here.</a>
                </p>
              </div>
              <div className="contact_info_item">
                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">
                  Want to have a chat?
                </h6>
                <p className="f_400 f_size_15">
                  You've come to the right place! Please feel free to send us a
                  message and we'll try our best to get back to you as soon as
                  possible.
                </p>
              </div>
            </div>
            {this.state.messageSent ? (
              <PostMessage />
            ) : (
              <div className="contact_form col-lg-9">
                <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">
                  Leave a Message
                </h2>
                <form
                  action="#"
                  className="contact_form_box"
                  method="post"
                  id="contactForm"
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group text_box">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Your Name"
                          value={this.state.name}
                          onChange={this.changeValue}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group text_box">
                        <input
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Your Email"
                          value={this.state.email}
                          onChange={this.changeValue}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group text_box">
                        <input
                          type="text"
                          id="subject"
                          name="subject"
                          placeholder="Subject"
                          value={this.state.subject}
                          onChange={this.changeValue}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group text_box">
                        <textarea
                          name="message"
                          id="message"
                          cols="30"
                          rows="10"
                          placeholder="Enter Your Message . . ."
                          value={this.state.message}
                          onChange={this.changeValue}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn_three"
                    onClick={this.sendMessage}
                  >
                    Send Message
                  </button>
                </form>
                <div id="success">Your message succesfully sent!</div>
                <div id="error">
                  Opps! There is something wrong. Please try again
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default Contacts;
