import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Contacts from '../components/Contacts';
import PrototypeFooter from '../components/Footer/PrototypeFooter';
import FooterData from '../components/Footer/FooterData';
import AgencyAction from '../components/AgencyAction';

import { Helmet } from "react-helmet";

const browserTitle = "Contact Us - Survaye";

const About = () => {
    return(
        <>
        <Helmet>
          <title>{browserTitle}</title>
        </Helmet>
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Contact Us" Pdescription=""/>
            <Contacts/>
            <AgencyAction/>
            <PrototypeFooter rclass={'payment_footer_area_two'} FooterData={FooterData}/>
        </div>
        </>
    )
}
export default About;