import React, { Component } from "react";
import Reveal from "react-reveal/Reveal";

const PostMessage = () => {
  return (
    <div className="contact_form col-lg-7 card shadow p-5 mr-4">
      <div className="text-center">
        <h1>Thanks for your interest!</h1>
        <br/>
        <p>
          One of our account managers will be reaching out to you shortly to
          give you more information about how we can work together.
        </p>
        <br/>
        <p>
            We look forward to working with you!
        </p>
      </div>
    </div>
  );
};

class StartForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      locations: "",
      messageSent: false
    };
  }

  changeValue = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  sendMessage = e => {
    e.preventDefault();
    const { name, email, phone, locations } = this.state;
    fetch(`https://app.survaye.com/getStarted/send`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name,
        email,
        phone,
        locations
      })
    });
    this.setState({
      messageSent: true
    });
  };

  render() {
    return (
      <section className="startup_banner_area_three sec_pad">
        <div className="container">
          <Reveal effect="fadeInUp" duration={1100}>
            <div className="row">
              {this.state.messageSent ? (
                <PostMessage />
              ) : (
                <div className="contact_form col-lg-7 card shadow p-5 mr-4">
                  <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_10">
                    Just fill out the form below to get started.
                  </h2>
                  <p className="l_height20">
                    One of our account managers will reach out to you shortly
                    after.
                  </p>
                  <form className="contact_form_box" id="contactForm">
                    <div className="row">
                      <div className="col-lg-12">
                        <h2 className="f_size_16">Name</h2>

                        <div className="form-group text_box border">
                          <input
                            type="text"
                            id="name"
                            name="name"
                            value={this.state.name}
                            onChange={this.changeValue}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <h2 className="f_size_16">Email</h2>
                        <div className="form-group text_box border">
                          <input
                            type="text"
                            name="email"
                            id="email"
                            value={this.state.email}
                            onChange={this.changeValue}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <h2 className="f_size_16">Phone Number</h2>
                        <div className="form-group text_box border">
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            value={this.state.phone}
                            onChange={this.changeValue}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <h2 className="f_size_16"># of Locations</h2>
                        <div className="form-group text_box border">
                          <select
                            id="locations"
                            name="locations"
                            value={this.state.locations}
                            onChange={this.changeValue}
                          >
                            <option value="" disabled defaultValue></option>
                            <option value="1_5">1-5</option>
                            <option value="6_10">6-10</option>
                            <option value="11_15">11-15</option>
                            <option value="16_25">16-25</option>
                            <option value="26">25+</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <button className="btn_three" onClick={this.sendMessage}>
                      Get Started
                    </button>
                  </form>
                  <div id="success">Your message succesfully sent!</div>
                  <div id="error">
                    Opps! There is something wrong. Please try again
                  </div>
                </div>
              )}
              <div className="contact-form col-lg-4 card shadow p-5 mr-4">
                <div className="contact_info_item">
                  <h6 className="f_p f_size_20 t_color3 f_500 mb_20">
                    What happens next?
                  </h6>
                  <p className="f_400 f_size_15">
                    After you fill out the form, one of our account managers will reach out to get to get more information about your business and what you are looking to achieve.
                  </p>
                </div>
                <div className="contact_info_item">
                  <h6 className="f_p f_size_20 t_color3 f_500 mb_20">
                    Then what happens?
                  </h6>
                  <p className="f_400 f_size_15">
                    Once you get to talk to us, you will be all set and we will get you up and running to use our platform!
                  </p>
                  <br/>
                  <p className="f_400 f_size_15">
                    Don't worry, you'll have access to an account manager who will be able to help you anytime you want.
                  </p>
                </div>
              </div>
            </div>
          </Reveal>
        </div>
      </section>
    );
  }
}

export default StartForm;
