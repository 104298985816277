import React, { Component } from "react";
import Featuresitems from "./Featuresitems";

class Features extends Component {
  render() {
    var { aClass } = this.props;
    return (
      <section className={`agency_featured_area bg_color ${aClass}`}>
        <div className="container">
          <h2
            className="f_size_30 f_600 t_color3 l_height40 text-center wow fadeInUp"
            data-wow-delay="0.3s"
          >
            How does the platform work?
          </h2>
          <div className="features_info">
            <img
              className="dot_img"
              src={require("../../img/home4/dot.png")}
              alt=""
            />
            <Featuresitems
              rowClass="row flex-row-reverse"
              aClass="pr_70 pl_70"
              fimage="create_location-01.png"
              iImg="icon01.png"
              ftitle="Put the location of your business into the platform."
              descriptions="It doesn't matter how many locations you have, just enter them into the platform to get started. Once you've entered them in, they'll go through a review process, and afterwards you can use them again and again."
            />
            <Featuresitems
              rowClass="row agency_featured_item_two"
              aClass="pl_100"
              fimage="create_text-01.png"
              iImg="icon02.png"
              ftitle="Create an email or text message campaign for your goal."
              descriptions='This step refers to "goal" as what you are wanting to achieve. While the focus is often on getting more reviews, you can create campaigns to do just about anything - get feedback, direct customers to your social media, etc.'
            />
            <Featuresitems
              rowClass="row flex-row-reverse"
              aClass="pr_70 pl_70"
              fimage="text_send-01.png"
              iImg="icon3.png"
              ftitle="Send the message to the customer in only a couple clicks!"
              descriptions="Since you have the campaign created, it only takes a couple clicks to repeatedly send the campaigns to your customers."
            />
            <div className="dot middle_dot">
              <span className="dot1"></span>
              <span className="dot2"></span>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Features;
