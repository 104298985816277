import React from "react";
import SeoTitle from "../Title/SeoTitle";
import Fade from "react-reveal/Fade";
import { IoIosMail, IoMdPaperPlane, IoMdQrScanner, IoMdText, IoIosLink } from 'react-icons/io';

const Service = () => {
  return (
    <React.Fragment>
      <section className="payment_features_area">
        <div className="bg_shape shape_one"></div>
        <div className="bg_shape shape_two"></div>
        <div className="bg_shape shape_three"></div>
        <div className="container">
          <SeoTitle
            Title="How does it work?"
            TitleP="Survaye makes it easy to ask for feedback from your customers."
          />
          <div className="row seo_service_info">
            <Fade bottom duration={500}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <IoMdPaperPlane size="4em" color='#F4B705'/>
                    <h4>Send Text Messages</h4>
                  <p>
                    Use our platform to easily send text messages to your
                    clients asking to leave a review. It only takes a couple
                    clicks to get started!
                  </p>
                </div>
              </div>
            </Fade>
            <Fade bottom duration={700}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <IoIosMail size="4em" color='#66BB6A'/>
                    <h4>Send Emails</h4>
                  <p>
                    Create your own email campaigns by only filling out a few
                    fields, all you need to do afterwards is put in their email
                    and you're done.
                  </p>
                </div>
              </div>
            </Fade>
            <Fade bottom duration={1000}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                <IoMdQrScanner size="4em" color='#EF5350'/>
                    <h4>Generate QR Codes</h4>
                  <p>
                    Survaye makes it easy to generate QR codes that you can
                    place anywhere around your business. Let the QR code get the
                    reviews for you!
                  </p>
                </div>
              </div>
            </Fade>
            <div className="col-lg-12 text-center mt_40">
              <a href="/product" className="seo_btn seo_btn_one btn_hover">
                All Features
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="seo_features_one sec_pad">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-6">
              <div className="seo_features_img">
                <div className="round_circle"></div>
                <div className="round_circle two"></div>
                <img src={require("../../img/seo/createcampaign-01.png")} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <Fade bottom cascade>
                <div className="seo_features_content">
                  <h2>
                    Create text and emails campaigns in only a couple clicks.
                  </h2>
                  <p>
                    With Survaye, it's painless to create texts and emails you
                    can send to your clients. Better yet, when you create it
                    once, we save it for you to use again and again.
                  </p>
                  <div className="media seo_features_item">
                    <div className="icon">
                    <IoMdText size="2em" color='#66BB6A'/>
                    </div>
                    <div className="media-body">
                      <h3>Custom Messages</h3>
                      <p>
                        We don't require a template, you can create the contents
                        of the message.
                      </p>
                    </div>
                  </div>
                  <div className="media seo_features_item">
                    <div className="icon two">
                    <IoIosLink size="2em" color='#EF5350'/>
                    </div>
                    <div className="media-body">
                      <h3>Click Tracking</h3>
                      <p>
                        See how effective your campaigns are with our click
                        tracking.
                      </p>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section className="seo_features_one sec_pad" style={{paddingTop:"0px"}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="seo_features_img seo_features_img_two">
                <div className="round_circle"></div>
                <div className="round_circle two"></div>
                <img
                  src={require("../../img/seo/different_sites_links.png")}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
            <Fade bottom cascade>
                            <div className="seo_features_content">
                  <h2 className="wow fadeInUp">
                    Ask for Google reviews, or you can create your own
                    link.
                  </h2>
                  <h6 className="wow fadeInUp">
                    With Survaye, you can ask for customers to leave a review or
                    feedback from any website - you are not limited.
                  </h6>
                  <p className="wow fadeInUp">
                    We allow for you to create any text, email or QR campaign
                    with your own links. This means you can create campaigns
                    based on any review or feedback website. Don't worry, we
                    make sure that each location you have has it's own Google
                    review link as well.
                  </p>
                  <a
                    href="/product"
                    className="seo_btn seo_btn_one btn_hover wow fadeInUp"
                  >
                    Learn More
                  </a>
                </div>
                            </Fade>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Service;
