import React, { Fragment } from 'react';
import SeoTitle from '../components/Title/SeoTitle';

const Paymentprocess = () => {
    return(
      <Fragment>
        <section className="seo_fact_area sec_pad">
        <div className="home_bubble">
            <div className="bubble b_one"></div>
            <div className="bubble b_three"></div>
            <div className="bubble b_four"></div>
            <div className="bubble b_six"></div>
            <div className="triangle b_eight" data-parallax='{"x": 120, "y": -10}'><img src="img/seo/triangle_one.png" alt=""/></div>
        </div>
        <div className="container">
            <SeoTitle Title='Why are online reviews so important?'/>
            <div className="seo_fact_info">
                <div className="seo_fact_item">
                    <div className="text">
                        <div className="counter one">97%</div>
                        <p className="m-1">of customers read online reviews</p>
                    </div>
                </div>
                <div className="seo_fact_item">
                    <div className="text">
                        <div className="counter two">57%</div>
                        <p className="m-1">will only consider a business with >4 stars.</p>
                    </div>
                </div>
                <div className="seo_fact_item">
                    <div className="text">
                        <div className="counter three">91%</div>
                        <p className="m-1">trust online reviews as much as personal recommendations.</p>
                    </div>
                </div>
                <div className="seo_fact_item last">
                    <div className="text">
                        <div className="counter four">73%</div>
                        <p className='m-1'>trust a local business more after reading positive reviews.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </Fragment>
    )
}
export default Paymentprocess;