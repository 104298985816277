import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import StartForm from "../components/StartForm";
import PrototypeFooter from "../components/Footer/PrototypeFooter";
import FooterData from "../components/Footer/FooterData";

import { Helmet } from "react-helmet";

const browserTitle = "Get Started - Survaye";

const GetStarted = () => {
  return (
    <>
      <Helmet>
        <title>{browserTitle}</title>
      </Helmet>
      <div className="body_wrapper">
        <CustomNavbar mClass="menu_four" nClass="w_menu" />
        <StartForm />
        <PrototypeFooter
          rclass={"payment_footer_area_two"}
          FooterData={FooterData}
        />
      </div>
    </>
  );
};
export default GetStarted;
