import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import HRBanner from "../components/Banner/HRBanner";
import Service from "../components/Service/Service";
import AgencyAction from "../components/AgencyAction";
import Paymentprocess from "../components/Paymentprocess";
import PrototypeFooter from "../components/Footer/PrototypeFooter";
import FooterData from "../components/Footer/FooterData";
import BannerData from "../components/Banner/BannerData";

import { Helmet } from "react-helmet";

const browserTitle = "Survaye - Review Management";

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>{browserTitle}</title>
      </Helmet>
      <div className="body_wrapper">
        <CustomNavbar mClass="menu_four" nClass="w_menu" />
        <HRBanner BannerData={BannerData} />
        <Service />
        <Paymentprocess />
        <AgencyAction />
        <PrototypeFooter
          rclass={"payment_footer_area_two"}
          FooterData={FooterData}
        />
      </div>
    </>
  );
};

export default HomePage;
